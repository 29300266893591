import { todosConstants, userConstants } from '../_constants';
import { todosService } from '../_services';

export const todosActions = {
    getAll,
    createNew
};

function getAll() {
    return dispatch => {
        todosService.getAll()
            .then(
                todos => dispatch(success(todos)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: todosConstants.GETALL_REQUEST } }
    function success(todos) { return { type: todosConstants.GETALL_SUCCESS, todos } }
    function failure(error) { return { type: todosConstants.GETALL_FAILURE, error } }
}

function createNew(name) {
    
    return dispatch => {
        todosService.createNew(name)
        .then(
            todo => dispatch(success(todo)));
    };

    function success(todo) {
        return { type: todosConstants.CREATENEW_SUCCESS, todo }
    }
/*
    const todo = { 'name': 'geil', 'id': 3 }

    return { type: todosConstants.CREATENEW_SUCCESS, todo }
    */
}