import { authHeader } from '../_helpers';
import axios from 'axios';
import { userActions } from '../_actions/user.actions';

export const todosService = {
    getAll,
    createNew,
};

const config = { 'apiUrl': 'https://dariokueffer.ch/dir' };


function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return axios.get(`${config.apiUrl}/items/todos`, requestOptions).then((response) => {
        const data = response.data;
        return data.data;
    });
}

function createNew(name) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    //const name = 'test4';
    return axios.post(`${config.apiUrl}/items/todos`, {name}, requestOptions).then((response) => {
        const data = response.data;
        return data.data;
    });
}