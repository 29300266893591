import './App.css';
import { LoginPage } from './LoginPage';
import { HomePage } from './HomePage';
import { history } from './_helpers';
import { Dashboard } from './Dashboard';
import { KlaraExport } from './KlaraExport';


import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import { PrivateRoute } from './_components';

function App() {

  let navigate = useNavigate();

  history.listen((location) => {
    navigate(location.location.pathname, { replace: false });
  });

  return <div className="App">
    <Routes>
      <Route exact path="/klara-export" element={<PrivateRoute><KlaraExport /></PrivateRoute>} />

      <Route exact path="/login" element={<LoginPage />} />
      <Route exact path="/dashboard" element={<Dashboard />} />
    </Routes>
  </div>
}

export default App;