import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Navbar } from '../_components';

import { userActions, todosActions } from '../_actions';



export default class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: '' };
    }
    componentDidMount() {
        this.props.dispatch(todosActions.getAll());

        this.handleChange = this.handleChange.bind(this);

    }

    handleSubmit = e => {
        this.props.dispatch(todosActions.createNew(this.state.value));
        e.preventDefault();
    }

    handleChange = e => {
        this.setState({ value: e.target.value });
    }


    render() {
        const { user, users, todos } = this.props;
        console.log(this.state);
        return (
            <div className="page-wrapper">
                <Navbar />
                <div className="page-content">
                    <p>
                        <Link to="/login">Logout</Link>
                    </p>
                    <form onSubmit={this.handleSubmit}>
                        <input type="text" value={this.state.value} onChange={this.handleChange} />
                        <input type="submit" value="Submit"></input>
                    </form>
                    {users.loading && <em>Loading users...</em>}
                    {users.error && <span className="text-danger">ERROR: {users.error}</span>}
                    {todos.items &&
                        <ul>
                            {todos.items.map((user, index) =>
                                <li key={user.id}>
                                    {user.id + ' ' + user.name}
                                </li>
                            )}
                        </ul>
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    console.log(state);
    const { users, authentication } = state;
    const { user } = authentication;
    const { todos } = state;
    return {
        user,
        users,
        todos,
    };
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export { connectedHomePage as HomePage };