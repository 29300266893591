import { authHeader } from '../_helpers';
import axios from 'axios';
import { userActions } from '../_actions/user.actions';

export const userService = {
    login,
    logout,
    getAll
};

const config = { 'apiUrl': 'https://dariokueffer.ch/dir' };
axios.interceptors.response.use(async function (response) {
     
    return response;
}, async function (error) {
    const originalConfig = error.config;

    if (error.response.status === 401 && originalConfig._retry !== true) {
        originalConfig._retry = true;
        try {
            const response = await refreshToken();
            const accessToken = response.data.access_token;
            localStorage.setItem('user', JSON.stringify(accessToken));
            originalConfig.headers.Authorization = 'Bearer ' + accessToken;
            return axios(originalConfig);
        } catch (error){
            console.log(error);
        }

    }
    

    return Promise.reject(error);
});

function login(email, password) {
    const mode = 'cookie';
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, mode })
    };


    return axios.post(`${config.apiUrl}/auth/login`, { email, password, mode }, { 'headers': { 'Content-Type': 'application/json'}, withCredentials: true  })
        .then((response) => {
            const user = response.data;
            localStorage.setItem('user', JSON.stringify(user.data.access_token));
            return user.data.access_token;
        });
    /*
        return fetch(`${config.apiUrl}/auth/login`, requestOptions)
            .then(handleResponse)
            .then(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user.data.access_token));
                console.log(user);
                return user.data.access_token;
            })*/
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}
/*
function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    console.log(requestOptions);
    return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
}*/

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return axios.get(`${config.apiUrl}/users`, requestOptions).then((response) => {
        const data = response.data;
        return data.data;
    });
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function refreshToken() {
    const requestOptions = {
        method: 'POST',
        credentials: 'include'
    };
    console.log(requestOptions);
    return fetch(`${config.apiUrl}/auth/refresh`, requestOptions).then(handleResponse);
    /*
    return axios.post(`${config.apiUrl}/auth/refresh`, {}, requestOptions )
        .then((response) => {
            const user = response.data;
            localStorage.setItem('user', JSON.stringify(user.data.access_token));
            return user.data.access_token;
        });*/
}
/*
function refreshToken() {
    return axios.post(`${config.apiUrl}/auth/refresh`, {withCredentials: true});
}*/