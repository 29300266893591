import { todosConstants } from '../_constants';

export function todos(state = {items: []}, action) {
  switch (action.type) {
    case todosConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case todosConstants.GETALL_SUCCESS:
      console.log(action.todos)
      return {
        items: [...state.items, ...action.todos]
      };
    case todosConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    case todosConstants.CREATENEW_SUCCESS:
      console.log(action.todo)
      return {
        ...state,
        items: [...state.items, action.todo]
      };
    default:
      return state
  }
}