import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { Link, NavLink } from 'react-router-dom';


export const Navbar = () => {
    return (
        <div className="navbar">
            <div className="nav-item">
                <NavLink to="/dashboard" className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                >Dashboard</NavLink>
            </div>
            <div className="nav-item">
                <NavLink
                    to="/klara-export"
                    className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                >
                    Klara Export
                </NavLink>
            </div>
            <div className="nav-item">
                <NavLink to="/login">Logout</NavLink>
            </div>
        </div>
    );
}