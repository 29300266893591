import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Navbar } from '../_components';

import { useState } from 'react'

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { userActions, todosActions } from '../_actions';

const Dashboard = (props) => {
    const [csvFile, setCsvFile] = useState();

    const submit = (event) => {
        setCsvFile(event.target.files[0])

        const file = csvFile;
        const reader = new FileReader();
/*
        reader.onload = function (e) {
            const text = e.target.result;
            processCSV(text) // plugged in here
        }*/

        reader.readAsText(file);
    }

    const [csvArray, setCsvArray] = useState([]);

    const processCSV = (str, delim = '","') => {
        console.log(str);
        str = str.replace('"', '');
        const headers = str.slice(0, str.indexOf('\n')).split(delim);
        let rows = str.slice(str.indexOf('\n') + 1).split('\n');

        const id = rows.indexOf('')
        console.log(id);
        rows.splice(id, 1);

        const newArray = rows.map(row => {
            row = row.replace('"', '');
            const values = row.split(delim);
            const eachObject = headers.reduce((obj, header, i) => {
                obj[header] = values[i];
                return obj;
            }, {})
            return eachObject;
        })
        console.log(newArray);
        setCsvArray(newArray)
    }

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const exportToCSV = (csvData, fileName) => {
        const header = {
            "TYP": "TYP",
            "CONAM": "CONAM",
            "SAL": "SAL",
            "FRTNAM": "FRTNAM",
            "LSTNAM": "LSTNAM",
            "ADRS": "ADRS",
            "ADDADRS": "ADDADRS",
            "POSCOD": "POSCOD",
            "CIT": "CIT",
            "CONTR": "CONTR",
            "MADRS": "MADRS",
        };

        const newArray = csvData.map(item => {
            const obj = {
                "TYP": 2,
                "CONAM": "",
                "SAL": "H",
                "FRTNAM": item.Vorname,
                "LSTNAM": item.Nachname,
                "ADRS": item.Adresse,
                "ADDADRS": "",
                "POSCOD": item.PLZ,
                "CIT": item.Ort,
                "CONTR": "CH",
                "MADRS": item.Email,
            };

            return obj;
        });

        console.log(newArray);
        const empty = {
            "TYP": "",
            "CONAM": "",
            "SAL": "",
            "FRTNAM": "",
            "LSTNAM": "",
            "ADRS": "",
            "ADDADRS": "",
            "POSCOD": "",
            "CIT": "",
            "CONTR": "",
            "MADRS": "",
        };
        const info = {
            "TYP": "Bitte gib deine Eingaben nach dieser Zeile ein, der Import der Kunden / Partner startet ab Zeile 5. Bitte Zeilen 1 - 4 nicht löschen.",
            "CONAM": "",
            "SAL": "",
            "FRTNAM": "",
            "LSTNAM": "",
            "ADRS": "",
            "ADDADRS": "",
            "POSCOD": "",
            "CIT": "",
            "CONTR": "",
            "MADRS": "",
        };
        let xlsxData = [empty, empty, info];
        xlsxData = xlsxData.concat(newArray);
        const ws = XLSX.utils.json_to_sheet(xlsxData);

        const wb = { Sheets: { 'Partner': ws }, SheetNames: ['Partner'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    const { user, users, todos } = props;
    return (
        <div className="page-wrapper">
            <Navbar />
            <div className="page-content">
                <div>
                    <form id='csv-form'>
                        <input
                            type='file'
                            accept='.csv'
                            id='csvFile'
                            onChange={(e) => {
                                submit(e)
                            }}
                        >
                        </input>
                        <label className="button" for="csvFile">Choose File</label>
                        <br />
                        <button
                            onClick={(e) => {
                                e.preventDefault()
                            }}>
                            Submit
                        </button>
                    </form>
                    {csvArray.length > 0 ?
                        <ul className="list">
                            {
                                csvArray.map((item, i) => (
                                    <li className="list-item" key={i}>
                                        <span className="list-cell list-cell--small"> {item["Vorname"]}</span>
                                        <span className="list-cell list-cell--small"> {item["Nachname"]}</span>
                                        <span className="list-cell"> {item["Adresse"]}</span>
                                        <span className="list-cell list-cell--xsmall"> {item["PLZ"]}</span>
                                        <span className="list-cell list-cell--medium"> {item["Ort"]}</span>
                                        <span className="list-cell list-cell"> {item["Email"]}</span>
                                        <span className="list-cell list-cell--medium"> {item["Telefon"]}</span>
                                    </li>
                                ))
                            }</ul>
                        : null}
                    {csvArray.length > 0 ?
                        <button className="button" onClick={(e) => exportToCSV(csvArray, 'adsf')}>Exportieren</button>
                        : null}
                </div>

            </div>
        </div>
    );
}


export default Dashboard;


function mapStateToProps(state) {
    console.log(state);
    const { users, authentication } = state;
    const { user } = authentication;
    const { todos } = state;
    return {
        user,
        users,
        todos,
    };
}

const connectedHomePage = connect(mapStateToProps)(Dashboard);
export { connectedHomePage as Dashboard };